import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';
import PriceContainer from '../../components/services/PriceContainer';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '36px 136px 60px',
    minHeight: '333px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
};

const customH1 = {
    fontWeight: 600,
    fontFamily: 'Nunito',
    fontSize: '35px',
    lineHeight: '51px',
    color: colors.green,
};

const homeConsultationProps = {
    mainContainer: {
        padding: '43px 0px',
    },
};

const serviceIntroductionData = {
    title: 'Virtual Private Cloud',
    firstParagraph: 'Red privada, virtual y aislada para tus recursos.',
    secondParagraph: 'Organizá y aislá tus recursos en una infraestructura más segura para tus aplicaciones.',
    gatsbyImg: (<StaticImage src="../../images/vpc-intro_icon.svg" alt="Nube" />),
    isButton: false,
};
const casesOfUseData = {
    title: 'Casos de uso',
    subTitle: 'Se utiliza para separar',
    cases: [
        {
            image: (<StaticImage src="../../images/team_icon.svg" alt="Equipo" />),
            text: 'Ambientes por equipos de trabajo',
        },
        {
            image: (<StaticImage src="../../images/qa_icon.svg" alt="QA" />),
            text: 'Ambientes de producción y desarrollo QA',
        },
        {
            image: (<StaticImage src="../../images/leaf_icon.svg" alt="Recursos" />),
            text: 'Los recursos de cada cliente',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 4,
    customPadding: '50px 136px 0px',
    customImageHeight: { height: '50px' },
};
const priceContainerData = {
    title: 'Precio',
    text: (
        <Typography variant="h3" pt={2} style={{ maxWidth: '700px', fontWeight: 600 }}>
            Es un servicio sin costo para el tráfico entre recursos de la misma o distinta VPC.
        </Typography>
    ),
};

const Vpc = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />

        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />

        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '20px 24px 48px' }, ...infoWrapper })}>
                <Box
                    style={{ textAlign: 'left', width: '100%', marginBottom: '20px' }}

                >
                    <Typography variant="sectionH2">
                        Datos del servicio
                    </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={12} lg={4} pr={2}>
                        <Typography sx={{ ...customH1 }}>1.</Typography>
                        <Typography variant="h3" style={{ fontFamily: 'Nunito', lineHeight: '35px', fontWeight: 600 }}>
                            La red se crea con un servidor DHCP para asignar IPs privadas a las máquinas virtuales.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} pr={2}>
                        <Typography sx={{ ...customH1 }}>2.</Typography>
                        <Typography variant="h3" style={{ fontFamily: 'Nunito', lineHeight: '35px', fontWeight: 600 }}>
                            El default gateway de la red puede ser el router general o un
                            <Link
                                to="/services/cloud_firewall_and_vpn"
                                style={{ textDecoration: 'none', color: colors.green }}
                            >
                                &nbsp;Cloud Firewall and VPN.
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography sx={{ ...customH1 }}>3.</Typography>
                        <Typography variant="h3" style={{ fontFamily: 'Nunito', lineHeight: '35px', fontWeight: 600 }}>
                            El tamaño por default es de /27.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <PriceContainer priceContainerData={priceContainerData} />
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default Vpc;
